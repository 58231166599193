import React, { useEffect } from 'react';
import TooltipComponent from '../bootstrap-component/tooltip-component';
import { initializeModal } from '../../../util/util';
import { getTranslatedText } from '../../../util/util';
import usePageTranslation from '../../../hooks/usePageTranslation';
import EditTranslationIcon from '../edit-translation-icon/EditTranslationIcon';
import { useSelector } from 'react-redux';

interface ConfirmationPopupProps {
    id: string;
    title: string;
    content: string;
    onConfirm: () => void;
    onAbort?: () => void;
    setDoNotAsk?: (value: boolean) => void;
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({ id, title, content, onConfirm, onAbort, setDoNotAsk }) => {    
    const PageTranslation = usePageTranslation();
    const {TranslationEditFlag} = useSelector((state: any) => state.commonSlice);
    useEffect(() => {
        // Call the initializeModal function on mount/update
        initializeModal(id);
    
        return () => {
          // Call the initializeModal function on unmount/cleanup
          initializeModal(id);
        };
    }, [id]);

    const handleConfirm = () => {
        onConfirm();
    };

    const handleAbort = () => {
        if (onAbort){
            onAbort();
        }
    };
    const handleDoNotAskChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (setDoNotAsk) {
            setDoNotAsk(event.target.checked);
        }
    };

    return (
        <div className="modal fade modal-draggable" data-bs-backdrop="false" id={id} tabIndex={-1} aria-labelledby={`${id}Label`} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={`${id}Label`}>
                            <span dangerouslySetInnerHTML={{ __html: getTranslatedText(title, PageTranslation) }} />
                            {TranslationEditFlag && <EditTranslationIcon translationKey={title} translationType='text'/>}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p dangerouslySetInnerHTML={{ __html: getTranslatedText(content, PageTranslation) }}></p>
                        {TranslationEditFlag && <EditTranslationIcon translationKey={content} translationType='text'/>}
                        {setDoNotAsk &&
                        <div className="mb-3 form-check">
                            <input type="checkbox" 
                                className="form-check-input" 
                                id="doNotShowCheckbox"
                                //checked={doNotAsk}
                                onChange={handleDoNotAskChange}
                            /> 
                            <label className="form-check-label" htmlFor="doNotShowCheckbox">
                                <span dangerouslySetInnerHTML={{ __html: getTranslatedText('dontAskAgain.message', PageTranslation) }}/>
                                {TranslationEditFlag && <EditTranslationIcon translationKey='dontAskAgain.message' translationType='text'/>}
                            </label>
                        </div>
                        }
                    </div>
                    <div className="modal-footer">
                        <TooltipComponent title={getTranslatedText('abort.tooltip',PageTranslation)}>
                            <button type="button" className="btn btn-secondary" 
                                data-bs-dismiss="modal" 
                                onClick={handleAbort}
                            >
                                <span dangerouslySetInnerHTML={{ __html: getTranslatedText('abort.text',PageTranslation) }}/>
                            </button>
                        </TooltipComponent>
                        {TranslationEditFlag && (
                            <>
                                <EditTranslationIcon translationKey='abort.text' translationType='text' />
                                <EditTranslationIcon translationKey='abort.tooltip' translationType='tooltip' />
                            </>
                        )}
                        <TooltipComponent title={getTranslatedText('ok.tooltip',PageTranslation)}>
                            <button type="button" className="btn btn-primary"
                                data-bs-dismiss="modal"
                                onClick={handleConfirm}
                            >
                                <span dangerouslySetInnerHTML={{ __html: getTranslatedText('ok.text',PageTranslation) }}/>
                            </button>
                        </TooltipComponent>
                        {TranslationEditFlag && (
                            <>
                                <EditTranslationIcon translationKey='ok.text' translationType='text' />
                                <EditTranslationIcon translationKey='ok.tooltip' translationType='tooltip' />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationPopup;