import { convertMarkdownToHtml, convertToThousands, getTranslatedText, hideModal, ProcessingMessageKeyValues } from '../../../../util/util';
import Email from '../../../../assets/icons/email.svg';
import Like from '../../../../assets/icons/like.svg';
import Dislike from '../../../../assets/icons/dislike.svg';
import LikeFilled from '../../../../assets/icons/like-filled.svg';
import DislikeFilled from '../../../../assets/icons/dislike-filled.svg';
import Tab from '../../../../assets/icons/tab.svg';
import Comment from '../../../../assets/icons/comment.svg';
import Editor, { BtnBold, BtnItalic, EditorProvider, Toolbar } from 'react-simple-wysiwyg';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Card from '../../../common/card/card';
import { useGetUserProfileMutation, useSubmitSurveyResponseMutation } from '../../../../api-integration/secure/secure';
import { fullPageLoader, updateAlertMessage, updateUser, updateTileInfo } from "../../../../api-integration/commonSlice";
import TooltipComponent from '../../../common/bootstrap-component/tooltip-component';
import { useCommentPromptMutation, useGeneratePdfMutation, useRatePromptMutation, useSendAIResponseEmailMutation } from '../../../../api-integration/public/common';
import SendCommentModal from '../../../common/modal/send-comment';
import SendEmailModal from "../../../common/modal/send-email";
import StarRating from '../../star-rating/star-rating';
import StarCommentModal from '../../modals/star-comment-modal';
import usePageTranslation from '../../../../hooks/usePageTranslation';
import EditTranslationIcon from '../../../common/edit-translation-icon/EditTranslationIcon';
import SocialShareIcons from '../../../common/modal/social-share-icons';
import ChartRenderer from '../../../common/chart-renderer';
import { set } from 'react-hook-form';

const AiResponse = (props: any) => {
  const PageTranslation = usePageTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const { protocol, host } = window.location;
  const [html, setHtml] = useState<any>();
  const [totalCCUsed, setTotalCCUsed] = useState('0');
  const [averageCCUsed, setAverageCCUsed] = useState('0');
  const [promptId, setPromptId] = useState(0);
  //const [showSurveySuccessMsg, setShowSurveySuccessMsg] = useState(false);
  const [surveySuccessMsg, setSurveySuccessMsg] = useState('');
  const sendCommentModalId = "sendCommentModal";
  const sendEmailModalId = "sendEmailModal";
  const emailPattern = /\S+@\S+\.\S+/;
  const starRatingId = "starRatingId";

  const [userEmail, setUserEmail] = useState('');
  const [thumbsUpIcon, setThumbsUpIcon] = useState(Like);
  const [thumbsDownIcon, setThumbsDownIcon] = useState(Dislike);
  const [isIconDisabled, setIsIconDisabled] = useState(true);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [userRating, setUserRating] = useState('0');
  const [userRatingDown, setUserRatingDown] = useState('1');
  const [isShareIconVisible, setIsShareIconVisible] = useState(false);
  const [userComment, setUserComment] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [commentError, setCommentError] = useState("");
  const [likeDislikeEvent, setLikeDislikeEvent] = useState('');
  //const [showResponseSection, setShowResponseSection] = useState(true);
  const [localUserFirstPromptId, setLocalUserFirstPromptId] = useState(0);

  const [ratings,setRatings] = useState<number>(0);    // State for default rating when the screen appears.
  const maxRating = 7;  // Variable to set maximum rating.
  const [tempRating, setTempRating] = useState<number>(0); // This state will be used to send the temporary stars to the modal when they are clicked.
  const [isChartVisible, setIsChartVisible] = useState(false);
  const [chartObject, setChartObject] = useState<any>({});
  const [processingMsgKey, setProcessingMsgKey] = useState(ProcessingMessageKeyValues[0]);
  const [currentMsgIndex, setCurrentMsgIndex] = useState(0);
  let myInterval: any;
  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') || 'false');
  const { user, TranslationEditFlag} = useSelector((state: any) => state.commonSlice);
  const [isHidden, setIsHidden] = useState(props?.isLibraryTypeChanged);
      
  const [RateGPTResponse, { data: rateResponse, isLoading: isRateLoading, isSuccess: isRateSuccess, isError: isRateError, error: rateError }]
  = useRatePromptMutation();

  const [getUserDetailsAPI, { data: userInfo, isLoading: isUserDetailLoding, isSuccess: isUserDetailSuccess, isError  }] =
  useGetUserProfileMutation();

  const [submitSurveyResponseAPI, { data: surveyResponse, isLoading: isSurveyLoading, isSuccess: isSurveySuccess, isError: isSurveyError, error: surveyError }] =
  useSubmitSurveyResponseMutation();

  const [generatePdfAPI, { data: pdfResponse, isLoading: isPdfLoading, isSuccess: isPdfSuccess, isError: isPdfError, error: pdfError }] = 
  useGeneratePdfMutation();

  const [SendAIResponseEmail, { data: sendAIResponseEmailResponse, isLoading: isSendLoading, isSuccess: isSendSuccess, isError: isSendError, error: err }] =
  useSendAIResponseEmailMutation();
  
  const [commentAPI, { data: commentResposne, isLoading: isCommentLoading, isSuccess: isCommentSuccess, isError: isCommentError, error: ommentError }] =
  useCommentPromptMutation();

  useEffect(() => {
    // Display the first message immediately
    setProcessingMsgKey(ProcessingMessageKeyValues[0]);
    setCurrentMsgIndex(0);

    // Start the interval after 5 seconds
    const initialTimeout = setTimeout(() => {
      const myInterval = setInterval(() => {
        setCurrentMsgIndex((prevIndex) => {
          if (prevIndex < ProcessingMessageKeyValues.length - 1) {
            const nextIndex = prevIndex + 1;
            setProcessingMsgKey(ProcessingMessageKeyValues[nextIndex]);
            return nextIndex;
          } else {
            clearInterval(myInterval);
            return prevIndex;
          }
        });
      }, 5000);
    }, 5000);

    return () => {
      clearTimeout(initialTimeout);
      clearInterval(myInterval);
    };
  }, [props?.loading , props?.refineLoading]);

  const onChange = (e: any) => {
    setHtml(e.target.value)
  }
  const rateGptBlue = async (rateVal: number, comment: string) => {
    // dispatch(fullPageLoader(true));
    // setUserRating(rateVal)
    // setLikeDislikeEvent('like');
    RateGPTResponse({ 
      userPromptId: props?.askGPTResponse?.userPromptId, 
      userRating: rateVal,
      userComment: comment
    });
  };

  const sendEmailDirectly = () => {
    dispatch(fullPageLoader(true));
    let user = JSON.parse(localStorage.getItem('user') as string) || {};
    const aiAdvice = props?.askGPTResponse?.aiAdvice ? props?.askGPTResponse?.aiAdvice : "";
    sendAIResponseEmail({ 
      GPTBlueUserPrompt: props?.prompt?.PROMPTNAME, 
      GPTBlueUserEmail: user?.userEmail, 
      userPromptId: props?.askGPTResponse?.userPromptId, 
      GPTBlueResponse: convertMarkdownToHtml(aiAdvice, 'stdText') 
    })
  }
  const sendEmail = () => {
    if (userEmail.length > 0 && emailPattern.test(userEmail)) {
      setEmailError(false);
      dispatch(fullPageLoader(true));
      sendAIResponseEmail({ 
        GPTBlueUserPrompt: props?.prompt?.PROMPTNAME, 
        GPTBlueUserEmail: userEmail, 
        userPromptId: props?.askGPTResponse?.userPromptId, 
        GPTBlueResponse: props?.askGPTResponse?.aiAdvice 
      })
      .then(() => {
        // This function hides the modal
        hideModal(sendEmailModalId);
      })
    } else {
      setEmailError(true);
    }
  }

  const sendComment = () => {
    if (userComment.length > 0) {
      setCommentError("");
      dispatch(fullPageLoader(true));
      commentAPI({
        "promptResponseId": props?.askGPTResponse?.userPromptId,
        "userComment": userComment
      })
      .then(() => {
        // This function hides the modal.
        hideModal(sendCommentModalId);
      })
    } else {
      setCommentError("comment.required.message");
    }
  }

  const generatePdf = () => {
    dispatch(fullPageLoader(true));
    const aiAdvice = props?.askGPTResponse?.aiAdvice? props?.askGPTResponse?.aiAdvice : ""
    generatePdfAPI({ 
      "promptResponseId": props?.askGPTResponse?.userPromptId, 
      promptResponse: convertMarkdownToHtml(aiAdvice, 'stdText') 
    })
  }

  const emailClicked = () => {
    isLoggedIn ? sendEmailDirectly() : setShowEmailModal(true)
  }

  useEffect(() => {
    if(isSurveySuccess) {
      dispatch(fullPageLoader(false));
      props.setShowResponseSection(false);
      props.setShowSurveySuccessMsg(true);
      setSurveySuccessMsg(surveyResponse?.message);
    }
    else if (isSurveyError || surveyError) {
      dispatch(fullPageLoader(false));
      dispatch(updateAlertMessage({ status: 'error', message: surveyResponse?.message }))
    }
  }, [isSurveySuccess, isSurveyError, surveyError])

  useEffect(() => {
    setIsHidden(props?.isLibraryTypeChanged);
  }, [props?.isLibraryTypeChanged]);

  useEffect(() => {
    const shouldDisableIcon = (!props?.askGPTResponse || props?.askGPTResponse?.success === false || props?.loading || !params.id || !props.showResponseSection || props?.isIterative);
    setIsIconDisabled(shouldDisableIcon);
    setRatings(0);
  }, [props?.askGPTResponse, props?.loading, params.id, props.showResponseSection, props?.isIterative]);

  const handleFinishClick = () => {
    setIsIconDisabled(false);
    if (props?.isIterative) {
      props?.setIsIterativeFinished(true);
    }
  };

  const sendAIResponseEmail = async (data: any) => {
    dispatch(fullPageLoader(true));
    SendAIResponseEmail(data)
  }

useEffect(() => {
    if (sendAIResponseEmailResponse?.success == true) {
      dispatch(updateAlertMessage({ status: 'success', message: sendAIResponseEmailResponse?.message }))
      dispatch(fullPageLoader(false));
      setShowEmailModal(false)
    }
    else if ((sendAIResponseEmailResponse?.success == false)) {
      dispatch(updateAlertMessage({ status: 'error', message: sendAIResponseEmailResponse?.message }))
      dispatch(fullPageLoader(false));
      setShowEmailModal(false)
    }
  }, [isSendSuccess, isSendError])

  useEffect(() => {
    if(props?.prompt?.ALLOWSOCIALSHARE){
      setIsShareIconVisible(props?.prompt?.ALLOWSOCIALSHARE === 1 ? true : false);
    }
  }, [props?.prompt]);
  
  useEffect(() => {
    if (props?.askGPTResponse || props?.refineResults) {
      dispatch(fullPageLoader(false));
    }
    if(props?.refineResults?.aiAdvice && props.userPromptAction == 'refineResult') {
      // When user click on refine result button
      setTotalCCUsed('0');
      getUserDetailsAPI({});
      setHtml(convertMarkdownToHtml(props?.refineResults?.aiAdvice, 'stdText'));
      setTotalCCUsed(convertToThousands(props?.refineResults?.totalUsedCC));
      setPromptId(props?.prompt?.GPTBLUEPROMPTID);
    }
    else if (props?.askGPTResponse?.aiAdvice)  {
      // When user click on AskGPT button
      setTotalCCUsed('0');
      getUserDetailsAPI({});
      setLocalUserFirstPromptId(props?.askGPTResponse?.userPromptId);
      //setHtml(convertMarkdownToHtml(props?.askGPTResponse?.aiAdvice, 'stdText'));
      setTotalCCUsed(convertToThousands(props?.askGPTResponse?.totalUsedCC));
      setPromptId(props?.prompt?.GPTBLUEPROMPTID);

      const chartsData = props?.askGPTResponse?.chartsData;
      if (chartsData && Object.keys(chartsData).length > 0) {
        setIsChartVisible(true);
        setChartObject(chartsData);
        setHtml(convertMarkdownToHtml(props?.askGPTResponse?.aiAdvice, 'stdText'));
      }
      else{
        setHtml(convertMarkdownToHtml(props?.askGPTResponse?.aiAdvice, 'stdText'));
      }
    }
  }, [props?.askGPTResponse?.aiAdvice, props?.refineResults?.aiAdvice])

  useEffect(() => {
    if (isUserDetailSuccess) {
      dispatch(fullPageLoader(false));
      //if(userInfo?.user?.accountType && userInfo?.user?.accountType == 'user') {
      if(isLoggedIn) {
        let user = JSON.parse(localStorage.getItem('user') as string);
        user.totalCCPoints = userInfo?.user?.totalCCPoints;
        dispatch(updateUser(user));
        localStorage.setItem('user', JSON.stringify(user));
      }
    }
  }, [isUserDetailSuccess, userInfo])

  useEffect(() => {
    if (rateResponse?.success == true) {
      dispatch(updateAlertMessage({ status: 'success', message: rateResponse?.message }))
      dispatch(fullPageLoader(false));
      if (likeDislikeEvent == 'like') {
        if (userRating == "1") {
          setThumbsUpIcon(LikeFilled)
          if (thumbsDownIcon == DislikeFilled) {
            setThumbsDownIcon(Dislike)
            setUserRatingDown("1")
          }
        }
        else {
          setThumbsUpIcon(Like)
        }
        props?.onRatingPrompt()
      }
      if (likeDislikeEvent == 'dislike') {
        if (userRatingDown == '1') {
          setThumbsDownIcon(Dislike)
        } else {
          setThumbsDownIcon(DislikeFilled)
          if (thumbsUpIcon == LikeFilled) {
            setThumbsUpIcon(Like)
            setUserRating("0")
          }
        }
        props?.onRatingPrompt()
      }
    }
    else if ((rateResponse?.success == false)) {
      dispatch(updateAlertMessage({ status: 'error', message: rateResponse?.message }))
      dispatch(fullPageLoader(false));
    }

  }, [isRateSuccess, isRateError])

  useEffect(() => {
    if (commentResposne?.success == true) {
      dispatch(updateAlertMessage({ status: 'success', message: commentResposne?.message }))
      dispatch(fullPageLoader(false));
      props?.onRatingPrompt()
    }
    else if ((commentResposne?.success == false)) {
      dispatch(updateAlertMessage({ status: 'error', message: commentResposne?.message }))
      dispatch(fullPageLoader(false));
    }
  }, [isCommentSuccess, isCommentError])


  useEffect(() => {
    if (pdfResponse?.success == true) {
      dispatch(updateAlertMessage({ status: 'success', message: pdfResponse?.message }))
      dispatch(fullPageLoader(false));
      // window.open(pdfResponse?.pdfURL, '_blank');
      var file_path = pdfResponse?.pdfURL;
      var a = document.createElement('A') as any;
      a.href = file_path;
      //  This will open up the pdf in new page. 
      a.target = '_blank';
      a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    else if ((pdfResponse?.success == false)) {
      dispatch(updateAlertMessage({ status: 'error', message: pdfResponse?.message }))
      dispatch(fullPageLoader(false));
    }
    if (isPdfError) {
      dispatch(fullPageLoader(false));
      dispatch(updateAlertMessage({ status: 'error', message: pdfResponse?.message }))
    }

  }, [isPdfSuccess, isPdfError, pdfResponse]);
  
  useEffect(() => {
    setThumbsUpIcon(Like)
    setThumbsDownIcon(Dislike);
    setUserRating('0')
    setUserRatingDown('1')
  }, [props?.askGPTResponse])

  const handleSetRating = (rating: number, comment: string) => {
    setRatings(rating);
    rateGptBlue(rating-1,comment);
  }

  const handleTempRating = (rating: number) => {
    setTempRating(rating);
  }

  return (
    <>
      <Card id={props?.cardId}
        titleType={1} 
        cardHeightClass={props?.page == 'dtd' ? '' : 'h-100'} 
        title={props?.cardTitle}
        help={true} Feedback={props.pageType == 'embed' ? false : true} 
        logo={true} share={props.pageType == 'embed' ? false : true} 
        helpTitle={props?.cardHelpTitle}
        helpContent={props?.cardHelpContent}
      >
        <div className='d-flex flex-column align-content-between h-100'>
        <div className='d-flex justify-content-between align-items-center mb-3'>
          <div className='fw-bold'>
            {getTranslatedText('totalCCUsed.text', PageTranslation)}
            {TranslationEditFlag && (
                <EditTranslationIcon translationKey="totalCCUsed.text" translationType='text' />
            )}
            :&nbsp;
            {totalCCUsed ? convertToThousands(totalCCUsed) : getTranslatedText('notAvailable.text', PageTranslation)}
          </div>
          {/* <div className={`text-end ${props?.page === 'dtd' ? 'd-none' : ''} ${(!props?.askGPTResponse || props?.askGPTResponse?.success === false || props?.loading || !params.id || !props.showResponseSection || props?.isIterative) ? 'disabled-item' : ''}`}> */}
          <div className={`text-end ${props?.page === 'dtd' ? 'd-none' : ''} ${isIconDisabled ? 'disabled-item' : ''}`}>
            <div className="d-inline-block text-center px-2">
              <div className="mb-1">
                {getTranslatedText('rate.text', PageTranslation)}
                {TranslationEditFlag && (
                    <EditTranslationIcon translationKey="rate.text" translationType="text" />
                )}
              </div>
              <div className="d-inline-block">
                <StarRating
                  maxRating = {maxRating}
                  defaultRating = {ratings}
                  // onSetRating = {handleSetRating}
                  onTempRating = {handleTempRating}
                  messages = {
                    [
                      { color: 'var(--bs-gray)', tooltip: getTranslatedText('ratingStar1.tooltip', PageTranslation)  },
                      { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar2.tooltip', PageTranslation) },
                      { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar3.tooltip', PageTranslation) },
                      { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar4.tooltip', PageTranslation)  },
                      { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar5.tooltip', PageTranslation) },
                      { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar6.tooltip', PageTranslation)  },
                      { color: 'var(--bs-secondary)', tooltip: getTranslatedText('ratingStar7.tooltip', PageTranslation)  }
                    ]
                  }
                />
                {TranslationEditFlag && (
                <>
                  <EditTranslationIcon translationKey="ratingStar1.tooltip" translationType="tooltip" />
                  <EditTranslationIcon translationKey="ratingStar2.tooltip" translationType="tooltip" />
                  <EditTranslationIcon translationKey="ratingStar3.tooltip" translationType="tooltip" />
                  <EditTranslationIcon translationKey="ratingStar4.tooltip" translationType="tooltip" />
                  <EditTranslationIcon translationKey="ratingStar5.tooltip" translationType="tooltip" />
                  <EditTranslationIcon translationKey="ratingStar6.tooltip" translationType="tooltip" />
                  <EditTranslationIcon translationKey="ratingStar7.tooltip" translationType="tooltip" />
                </>
                )}
              </div>
            </div>
            <div className="d-inline-block text-center px-2">
              <div className="mb-1">
                {getTranslatedText('comment.text', PageTranslation)}
                {TranslationEditFlag && (
                    <EditTranslationIcon translationKey="comment.text" translationType="text" />
                )}
              </div>
              <div className="d-inline-block">
                <TooltipComponent title={getTranslatedText('ioResponseComment.tooltip', PageTranslation)} >
                  <img src={Comment} className='h-1-5 cursor-pointer' 
                    data-bs-toggle="modal"
                    data-bs-target={`#${sendCommentModalId}`}
                  />
                </TooltipComponent>
                {TranslationEditFlag && (
                  <EditTranslationIcon translationKey="ioResponseComment.tooltip" translationType="tooltip" />
                )}
              </div>
            </div>
            <div className="d-inline-block text-center px-2">
              <div className="mb-1">
                {getTranslatedText('email.text', PageTranslation)}
                {TranslationEditFlag && (
                    <EditTranslationIcon translationKey="email.text" translationType="text" />
                )}
              </div>
              <div className="d-inline-block">
                <TooltipComponent title={getTranslatedText('ioResponseEmail.tooltip', PageTranslation)} >
                  <img src={Email} className='h-1-5 cursor-pointer' 
                    data-bs-toggle={isLoggedIn ? undefined : "modal"} 
                    data-bs-target={isLoggedIn ? undefined : `#${sendEmailModalId}`} 
                    onClick={isLoggedIn ? emailClicked : undefined}
                  />
                </TooltipComponent>
                {TranslationEditFlag && (
                  <EditTranslationIcon translationKey="ioResponseEmail.tooltip" translationType="tooltip" />
                )}
              </div>
            </div>
            <div className="d-inline-block text-center px-2">
              <div className="mb-1">
                {getTranslatedText('pdf.text', PageTranslation)}
                {TranslationEditFlag && (
                  <EditTranslationIcon translationKey="pdf.text" translationType="text" />
                )}
              </div>
              <div className="d-inline-block">
                <TooltipComponent title={getTranslatedText('ioResponsePdf.tooltip', PageTranslation)} >
                  <img src={Tab} className='h-1-5 cursor-pointer' onClick={generatePdf} />
                </TooltipComponent>
                {TranslationEditFlag && (
                  <EditTranslationIcon translationKey="ioResponsePdf.tooltip" translationType="tooltip" />
                )}
              </div>
            </div>
          </div>
        </div>
        { !(props?.refineLoading || props?.loading || isHidden) 
          && ( props?.askGPTResponse?.aiAdvice || props?.refineResults?.aiAdvice) 
          && props.showResponseSection
          && (
            ((props?.isIterative && !props?.isIterativeFinished) || props?.isSurveyPrompt) ?
              <>
                {props?.isSurveyPrompt &&
                <div className='mb-3'>
                  <span dangerouslySetInnerHTML={{__html: getTranslatedText('surveyPromptConfirmation.message', PageTranslation)}} />
                  {TranslationEditFlag && (
                      <EditTranslationIcon translationKey="surveyPromptConfirmation.message" translationType='text' />
                  )}
                </div>
                }
                <div id="AIAdviceResponse" className="m-2 text-break">
                  <EditorProvider>
                  <div className="overflow-auto scrollbar h-35vh" >
                    <Editor value={html} onChange={onChange}>
                      <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                      </Toolbar>
                    </Editor>
                    </div>
                  </EditorProvider>
                </div>
                <div className="mt-2 text-center">
                  <div className="d-inline-block">
                    <TooltipComponent title={props?.isSurveyPrompt ? getTranslatedText('reviewResponse.tooltip', PageTranslation) : getTranslatedText('refineResult.tooltip', PageTranslation)} >
                      <button type='button' 
                        disabled={props?.refineLoading} 
                        onClick={() =>{ 
                          props.refineResult(html, localUserFirstPromptId);
                          props?.setUserPromptAction('refineResult');
                          props?.setShowResponseSection(true);
                          props?.setShowSurveySuccessMsg(false);
                        }} 
                        className={`btn btn-primary btn-md rounded-pill px-3 mt-3 ${props?.refineLoading ? 'disabled' : ''}`} 
                      >
                          {props?.isSurveyPrompt 
                          ? 
                          getTranslatedText('reviewResponse.text', PageTranslation)
                          : 
                          getTranslatedText('refineResult.text', PageTranslation)
                          }
                      </button>
                    </TooltipComponent>
                    {TranslationEditFlag && (
                        <>
                            <EditTranslationIcon translationKey={props?.isSurveyPrompt ? "reviewResponse.text" : "refineResult.text"} translationType='text' />
                            <EditTranslationIcon translationKey={props?.isSurveyPrompt ?"reviewResponse.tooltip" : "refineResult.tooltip"} translationType='tooltip' />
                        </>
                    )}
                  </div>
                  {props?.isSurveyPrompt 
                    ?
                    <div className="ms-2 d-inline-block">
                      <TooltipComponent title={getTranslatedText('respondSurvey.tooltip', PageTranslation)}>
                        <button type='button' 
                          //disabled={props?.refineLoading} 
                          onClick={() => {
                            dispatch(fullPageLoader(true));
                            submitSurveyResponseAPI({ 
                              gptBluePromptId: promptId, 
                              surveyResponse: html 
                            })
                          }} 
                          className="btn btn-primary btn-md rounded-pill px-3 mt-3 "
                        >
                          {getTranslatedText('finish.text', PageTranslation)}
                        </button>
                      </TooltipComponent>
                      {TranslationEditFlag && (
                        <>
                          <EditTranslationIcon translationKey="finish.text" translationType='text' />
                          <EditTranslationIcon translationKey="respondSurvey.tooltip" translationType='tooltip' />
                        </>
                      )}  
                    </div>
                    :
                    <div className="ms-2 d-inline-block">
                      <TooltipComponent title={getTranslatedText('finishExecutionProcess.tooltip', PageTranslation)}>
                        <button type='button' 
                          onClick={handleFinishClick} 
                          className="btn btn-primary btn-md rounded-pill px-3 mt-3"
                        >
                          {getTranslatedText('finish.text', PageTranslation)}
                        </button>
                      </TooltipComponent>
                      {TranslationEditFlag && (
                        <> 
                          <EditTranslationIcon translationKey="finish.text" translationType='text' />
                          <EditTranslationIcon translationKey="finishExecutionProcess.tooltip" translationType='tooltip' />
                        </>
                      )}
                    </div>
                  }
                </div>
              </>
              :
              <>
              {isChartVisible 
              ? 
                <ChartRenderer 
                  content={html} 
                  chartData={chartObject} 
                /> 
              : 
                <div 
                  className="p-2  overflow-auto scrollbar h-41vh" 
                  dangerouslySetInnerHTML={{ __html: html }} 
                />
              }
              
              </>
        )}
        {
          !(props?.loading || props.refineLoading) && !props?.askGPTResponse && !props?.isAskGPTError && !props?.refineResults?.aiAdvice &&
          <div className="py-1">
            {props?.page === 'dtd' 
            ? 
              <>
                <span dangerouslySetInnerHTML={{__html: getTranslatedText('studioPromptExecution.message', PageTranslation)}} />
                {TranslationEditFlag && (
                    <EditTranslationIcon translationKey="studioPromptExecution.message" translationType='text' />
                )}
              </>
            :
              (props?.page === 'io' && props?.isSurveyPrompt) 
              ?
                <> 
                  <span dangerouslySetInnerHTML={{__html: getTranslatedText('surveyPromptExecution.message', PageTranslation)}} />
                  {TranslationEditFlag && (
                    <EditTranslationIcon translationKey="surveyPromptExecution.message" translationType='text' />
                  )}
                </>
              :
                <>
                  <span dangerouslySetInnerHTML={{__html: getTranslatedText('ioPromptExecution.message', PageTranslation)}} />
                  {TranslationEditFlag && (
                    <EditTranslationIcon translationKey="ioPromptExecution.message" translationType='text' />
                  )}
                </>
            }
          </div>
        }
        {(props?.loading || props?.refineLoading) && 
          <div className='ps-2 py-1 d-flex'>
            <span dangerouslySetInnerHTML={{__html: getTranslatedText(processingMsgKey, PageTranslation)}} />
            {TranslationEditFlag && (
              <EditTranslationIcon translationKey={processingMsgKey} translationType='text' />
            )}
            <span className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading…</span>
            </span>
          </div>
        }
        {isSurveyLoading && 
          <div className='text-center'>
            <div className="spinner-border spinner-border-sm text-primary" role="status">
              <span className="visually-hidden">Loading…</span>
            </div>
          </div>
        }
        {props.showSurveySuccessMsg && 
          <div className="text-center mt-2">{surveySuccessMsg}</div>
        }
        {isShareIconVisible &&
          <div className={`mt-auto d-flex justify-content-center ${props?.page === 'dtd' ? 'd-none' : ''} ${isIconDisabled ? 'disabled-item' : ''}`}>
            <div className="mt-3">
              <SocialShareIcons
                  size={20}
                  shareObject = {"prompt"}
                  shareObjectId={props?.prompt?.GPTBLUEPROMPTID?.toString() || ""} 
                  contentShareTitle={props?.prompt?.PROMPTNAME || ""} 
                  contentShareDesc= {html && `${props?.prompt?.DEFAULTACTION} \n\n ${html.replace(/<\/?[^>]+(>|$)/g, "")}` || ""}
                  sharePromptAuthor = {props?.prompt?.OWNERFIRSTNAME + ' ' + props?.prompt?.OWNERLASTNAME || ""} 
                  contentShareUrl={`${protocol}//${host}/app/io/${props?.prompt?.URLCODE}` || ""} 
              />
            </div>
          </div>
        }
        </div>
      </Card>
      <SendEmailModal 
        id={sendEmailModalId}
        onSendEmail={sendEmail}
        emailError={emailError}
        setEmailError={setEmailError}
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        emailPattern={emailPattern}
      />
      <SendCommentModal 
        id={sendCommentModalId} 
        onSendComment={sendComment}
        commentError={commentError} 
        setCommentError={setCommentError} 
        userComment={userComment} 
        setUserComment={setUserComment}  
      />
      <StarCommentModal 
        id = {starRatingId}
        maxRating = {maxRating}
        rating ={tempRating}
        handleRate={handleSetRating}
        messages = {
          [
            { color: 'var(--bs-gray)', tooltip: getTranslatedText('ratingStar1.tooltip', PageTranslation)  },
            { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar2.tooltip', PageTranslation)  },
            { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar3.tooltip', PageTranslation)  },
            { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar4.tooltip', PageTranslation)  },
            { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar5.tooltip', PageTranslation)  },
            { color: 'var(--bs-primary)', tooltip: getTranslatedText('ratingStar6.tooltip', PageTranslation)  },
            { color: 'var(--bs-secondary)', tooltip: getTranslatedText('ratingStar7.tooltip', PageTranslation)  }
          ]
        }
      />
    </>
  )
}

export default AiResponse;