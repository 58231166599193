import i18n from "../../../i18n/i18n";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fullPageLoader, updateAlertMessage, updateIsSessionExpired, updateTileInfo, updatePageTranslation, updateUser, updatePageMemory } from "../../../api-integration/commonSlice";
import { useGetUserProfileMutation, useLogoutMutation, useUpdateUserSettingsMutation } from "../../../api-integration/secure/secure";
import { useEffect, useState } from "react";
import { clearCookies, getPageByURL, getTranslatedText } from "../../../util/util";
import Logo from '../../../assets/images/logo.png';
import { useGetPageTileInfoMutation } from "../../../api-integration/public/public";
import usePageTranslation from "../../../hooks/usePageTranslation";

interface LanguageMap {
  [key: string]: string;
}

const Header = () => {
  const PageTranslation = usePageTranslation();
  const {TranslationEditFlag} = useSelector((state: any) => state.commonSlice);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let isLoggedIn = localStorage.getItem('isLoggedIn');
  let user = localStorage.getItem('user');
  const pageName = getPageByURL(location.pathname);
  
  const languageMap: LanguageMap = {
    'en': 'English',
    'vi': 'vietnamese',
    // Add more languages here
  };
  const [language, setLanguage] = useState<string>(() => {
    const savedLanguage = localStorage.getItem('i18nextLng') || 'en';
    return languageMap[savedLanguage] || 'English';
  });

  const [logoutAPI, { data: loggoutOut, isLoading, isSuccess, isError, error: logoutError }] =
    useLogoutMutation();

    const [updateUserAPI, {data: updateUserData, isLoading: isUpdateUserLoading, isSuccess: isUpdateUserSuccess, isError: isUpdateUserError, error: updateUserError}]
    =useUpdateUserSettingsMutation();

  const [pageTileInfoAPI, { data: pageTileInfo, isLoading: isPageTileInfoLoading, isSuccess: isPageTileInfoSuccess, isError: isPageTileInfoError, error: pageTileInfoError }] =
    useGetPageTileInfoMutation();

  const [getUserDetailsAPI, { data: userDetails, isLoading: isUserDetailsLoading , isSuccess: isUserDetailsSuccess, isError: isUserDetailsError, error: userDetailserror}] =
    useGetUserProfileMutation();

  const logout = () => {
    dispatch(fullPageLoader(true));
    logoutAPI({});
  };

  useEffect(() => {
    if (location.pathname) {
      if (getPageByURL(location.pathname)) {
        dispatch(fullPageLoader(true));

        const promises = [
          pageTileInfoAPI({
            "Page": getPageByURL(location.pathname)
          })
        ];
  
        if (isLoggedIn || user != null) {
          const userDetailsPromise = getUserDetailsAPI({});
          userDetailsPromise.then(response => {
            // Check if response has a data property
            if ('data' in response && response?.data?.user) {
              localStorage.setItem('i18nextLng', response?.data?.user?.preferences?.userLanguage);
              // Update local storage on success
              const localUser = {
                userEmail: response?.data?.user?.email,
                userId: response?.data?.user?.userId,
                firstName: response?.data?.user?.firstName,
                lastName: response?.data?.user?.lastName,
                picPath: response?.data?.user?.picPath,
                totalCCPoints: response?.data?.user?.totalCCPoints,
                usrCreatedDate: response?.data?.user?.usrCreatedDate,
                referralId: response?.data?.user?.referralId,
                blcFlag: response?.data?.user?.blcFlag,
                betaUser: response?.data?.user?.betaUser,
                accId: response?.data?.user?.accId,
                orgId: response?.data?.user?.orgId,
                accountType: response?.data?.user?.accountType,
                showRefKey: response?.data?.user?.showRefKey,
                isNewUser: response?.data?.user?.isNewUser,
                showWelcomeNote: response?.data?.user?.isNewUser,
                isProfileComplete: response?.data?.user?.isProfileComplete,
                promptExeAlert: response?.data?.user?.preferences?.promptExeAlert,
                userLanguage: response?.data?.user?.preferences?.userLanguage,
                paginationRecordsPerPage: response?.data?.user?.preferences?.userPaginationRecordsPerPage,
                emailPrivacy: response?.data?.user?.preferences?.userPrivacy,
                sysUsageLevel: response?.data?.user?.sysUsageLevel,
                usrMessageAlertDuration: response?.data?.user?.preferences?.usrMessageAlertDuration,
                userPreferences: response?.data?.user?.preferences,
                userCompanyData: response?.data?.user?.companyData,
                userNotifications: response?.data?.user?.userNotifications,
                CCtoEUR: response?.data?.user?.CCtoEUR,
              }
              dispatch(updateUser(localUser));
              localStorage.setItem('user', JSON.stringify(localUser));
            }
            else{
              /*
                User's status is loggedIn in react but API did not return any data 
                  - either accessToken does not exists in HHTP only COOKIE
                  - OR ACCESSTOKEN is expired  
                Logout the user from react application
              */
              clearAndLogout();
            }
          });
          promises.push(userDetailsPromise);
        }
  
      Promise.all(promises)
        .then(() => {
          dispatch(fullPageLoader(false));
        })
        .catch(() => {
          dispatch(fullPageLoader(false));
        })
        .finally(() => {
          dispatch(fullPageLoader(false));
        });
      }
    }
  }, [location])

  const clearAndLogout = () => {
    localStorage.clear();
    clearCookies('accessToken');
    dispatch(updateIsSessionExpired(false));
    dispatch(updateAlertMessage({ status: 'error', message: getTranslatedText('sessionExpired.message',PageTranslation) }));
    localStorage.clear();
    navigate('/login');
  }

  useEffect(() => {
    if (isPageTileInfoSuccess) {
      if (pageTileInfo?.statusCode == 200 && pageTileInfo?.success == true) {
        dispatch(fullPageLoader(false));
        dispatch(updateTileInfo(pageTileInfo?.TileInfo));
        dispatch(updatePageTranslation(pageTileInfo?.pageTranslation));
        dispatch(updatePageMemory(pageTileInfo?.pageMemory));
        let tileIsLoggedIn = pageTileInfo?.isLoggedIn;
        
        // if (!tileIsLoggedIn && (isLoggedIn || user != null)) {
        //   //dispatch(updateAlertMessage({ status: 'error', message: t('message.session_expired') }));
        //   //localStorage.clear();
        //   //clearCookies('accessToken')
        //   //navigate('/login');
        // }
      } else {
        dispatch(fullPageLoader(false));
        dispatch(updateAlertMessage({ status: 'error', message: pageTileInfo?.message }));
      }
    }
    if (pageTileInfoError || isPageTileInfoError) {
      dispatch(fullPageLoader(false));
      dispatch(updateAlertMessage({ status: 'error', message: getTranslatedText('errorRatingCard.message',PageTranslation) }));
    }
  }, [isPageTileInfoSuccess, pageTileInfoError, isPageTileInfoError]);

  useEffect(() => {
    if (loggoutOut) {
      dispatch(fullPageLoader(false));
      localStorage.clear();
      dispatch(updateAlertMessage({ status: 'success', message: getTranslatedText('logout.success.message',PageTranslation) }));
      clearCookies('accessToken')
      navigate('/login');
    }
    if (logoutError) {
      dispatch(updateAlertMessage({ status: 'error', message: getTranslatedText('logout.failure.message',PageTranslation) }));
      dispatch(fullPageLoader(false));
    }
  }, [loggoutOut, loggoutOut])

  const handleLanguageChange = (selectedLanguage: string) => {
    localStorage.setItem('i18nextLng', selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    const languageName = languageMap[selectedLanguage] || "English"; // Default to 'English' if the selected language is not in the map
    setLanguage(languageName);
    updateUserAPI({userLanguage: selectedLanguage});
  };

  return (
    <>
    {pageName === 'login' ? null : (
    <div className="header p-5 d-flex justify-content-between align-items-center">
      <img src={Logo} className="logo" />

      {/* <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Language: {language}
      </a>
      <ul className="dropdown-menu">
        <li>
          <a className="dropdown-item" 
            href="#" 
            onClick={(event) => {
              event.preventDefault();
              handleLanguageChange('en');
            }}
          >
            English
          </a>
        </li>
        <li>
          <a className="dropdown-item" 
            href="#" 
            onClick={(event) => {
              event.preventDefault();
              handleLanguageChange('vi');
            }}
          >
            vietnamese
          </a>
        </li>
      </ul> */}
    </div>
    )}
    </>
  )
}

export default Header;